<template>
  <transition
    name="cookie"
    appear
  >
    <Wrapper
      v-show="!cookie"
      class="cookie"
      boxed
    >
      <component
        :is="$mq.isMobile ? 'div' : 'Flex'"
        class="cookie--wrapper"
        :column-gap="!$mq.isMobile ? 's' : undefined"
        :justify="!$mq.isMobile ? 'end' : undefined"
        :class="$mq.isMobile ? 't-center' : ''"
      >
        <div v-if="options.cookies.title">
          {{ options.cookies.title }}
        </div>
        <a
          href="https://nibirumail.com/cookies/policy/?url=www.alessandrachiarelli.com"
          target="_blank"
        >

          <Richtext
            v-if="options.cookies.text"
            :value="options.cookies.text"
          />
        </a>
        <Flex
          class="cookie--cta"
          :column-gap="$mq.isMobile ? 'l' : 's'"
          :justify="$mq.isMobile ? 'center' : undefined"
        >
          <Link
            :data="{
              title: 'Decline',
              fn: acceptCookie,
            }"
            class="t-uppercase"
          />
          <Link
            :data="{
              title: 'Accept',
              fn: acceptCookie,
            }"
            class="t-uppercase"
          />
        </Flex>
      </component>
    </Wrapper>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import Cookies from 'js-cookie';

import { loadScript } from '@/assets/js/utils';

import Richtext from '@/components/typo/richtext';
import Link from '@/components/typo/link';

export default {
  name: 'Cookie',
  components: {
    Richtext,
    Link,
  },
  data() {
    return {
      // eslint-disable-next-line no-undef
      cookie: !!Cookies.get(__VUE_WORDPRESS__.state.options?.cookies?.id),
    };
  },
  computed: {
    ...mapGetters(['transition', 'options']),
  },
  methods: {
    acceptCookie() {
      Cookies.set(this.options.cookies.id, '1', { expires: 365 });
      this.cookie = true;

      if (this.options.scripts && this.options.scripts.scripts_repeater) {
        this.options.scripts.scripts_repeater.forEach((item) => {
          if (!item.init) {
            if (item.inline) {
              const s = document.createElement('span');
              s.style.display = 'none';
              s.innerHTML = item.script;
              document.getElementsByTagName('body')[0].appendChild(s);
            } else {
              loadScript(item.script_src);
            }
          }
        });
      }

      if (this.$gtag) {
        this.$gtag.config(
          {
            params: {
              anonymize_ip: false,
              send_page_view: false,
            },
          },
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-enter-active,
.cookie-leave-active {
  transition: all 0.3s $ease-custom;
}

.cookie-enter,
.cookie-leave-to {
  transform: translateY(100%) translateY(30px);
}

.cookie {
  position: fixed;
  bottom: 0;
  padding-bottom: var(--spacer-s);
  padding-top: var(--spacer-s);
  left: 0;
  width: 100%;
  z-index: 2000;
  background: var(--primary);

  @include mq(m) {
    background: transparent
  }

  &--cta {
    padding-top: var(--spacer-s);

    @include mq(m) {
      padding-top: 0;
    }

    &>* {
      cursor: pointer;
    }
  }
}
</style>
