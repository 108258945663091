<template>
  <Wrapper
    boxed
    :class="{'header t-uppercase': true}"
  >
    <Grid>
      <router-link
        to="/"
        class="col col-9 col-s-3"
      >
        Alessandra Chiarelli
      </router-link>
      <Menu
        v-if="$route.params.slug !== 'about'"
      />
    </Grid>
  </Wrapper>
</template>

<script>
import Menu from '@/components/ui/menu';
import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  components: {
    Menu,
  },
  computed: {
    ...mapGetters(['farFrom']),
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  // background: linear-gradient(180deg, rgba(0,0,0,0.2), rgba(0,0,0,0));
  color: white;
  pointer-events: none;
  mix-blend-mode: difference;
  @include mq(s) {
    top: calc(50% - 18px);
  }
  @include mq(m) {
    top: calc(50% - 19px);
  }
}
nav {
  grid-column: 10/13;
  @include mq(s) {
    grid-column: 7/13;
    grid-column: 11/13;
  }
  @include mq(m) {
    grid-column: 9/12;
    grid-column: 11/12;
  }
}
a {
  pointer-events: auto;
  padding: var(--spacer-s) 0;
}
</style>
