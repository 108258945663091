<template>
  <component
    :is="mergeProps.tag"
    :class="['title', `title--${mergeProps.typo} t-uppercase`]"
    v-html="mergeProps.value"
  />
</template>

<script>
export default {
  name: 'Heading',
  props: {
    data: {
      type: Object,
      default: () => { },
    },
    value: {
      type: String,
      default: () => { },
    },
    typo: {
      type: String,
      default: () => { },
    },
    tag: {
      type: String,
      default: () => { },
    },
  },
  computed: {
    mergeProps() {
      return {
        typo: this.typo ? this.typo : this.data ? this.data.settings?.typo : 'default',
        value: this.value || this.data.value?.heading,
        tag: this.tag ? this.tag : this.data ? this.data.settings?.tag : 'h2',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding-bottom: var(--spacer-m);

  &:not(:first-child) {
    padding-top: var(--spacer-l)
  }
}
</style>
