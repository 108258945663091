<template>
  <Archive
    ref="archive"
    :data="{
      value: {
        post_type: 'work',
      },
      settings: {
        posts_per_page: '50',
        filters: [],
        offset: 0,
        order: 'asc',
        infinite_scroll: false
      },
    }"
    thumb="default"
  />
</template>

<script>
import Archive from '@/components/blocks/archive';

export default {
  name: 'Homepage',
  components: {
    Archive,
  },
};
</script>
<style lang="scss" scoped>
</style>
