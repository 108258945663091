var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"cookie","appear":""}},[_c('Wrapper',{directives:[{name:"show",rawName:"v-show",value:(!_vm.cookie),expression:"!cookie"}],staticClass:"cookie",attrs:{"boxed":""}},[_c(_vm.$mq.isMobile ? 'div' : 'Flex',{tag:"component",staticClass:"cookie--wrapper",class:_vm.$mq.isMobile ? 't-center' : '',attrs:{"column-gap":!_vm.$mq.isMobile ? 's' : undefined,"justify":!_vm.$mq.isMobile ? 'end' : undefined}},[(_vm.options.cookies.title)?_c('div',[_vm._v(" "+_vm._s(_vm.options.cookies.title)+" ")]):_vm._e(),_c('a',{attrs:{"href":"https://nibirumail.com/cookies/policy/?url=www.alessandrachiarelli.com","target":"_blank"}},[(_vm.options.cookies.text)?_c('Richtext',{attrs:{"value":_vm.options.cookies.text}}):_vm._e()],1),_c('Flex',{staticClass:"cookie--cta",attrs:{"column-gap":_vm.$mq.isMobile ? 'l' : 's',"justify":_vm.$mq.isMobile ? 'center' : undefined}},[_c('Link',{staticClass:"t-uppercase",attrs:{"data":{
            title: 'Decline',
            fn: _vm.acceptCookie,
          }}}),_c('Link',{staticClass:"t-uppercase",attrs:{"data":{
            title: 'Accept',
            fn: _vm.acceptCookie,
          }}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }