<template>
  <transition name="fade">
    <Flex
      v-show="show"
      :class="['modal', `modal-theme--${theme}`, 'overlay']"
      align="center"
      :tabindex="show ? 0 : -1"
    >
      <Wrapper
        v-if="data"
        :class="['modal-wrapper']"
        size="m"
        boxed
      >
        <Icon
          v-if="!data.mandatory"
          name="close"
          :fn="close"
          theme="minimal"
          size="m"
          class="icon--close"
        />

        <template v-if="data.type === 'content'">
          <Spacer all="m">
            <Heading
              :value="data.content.title"
            />
            <Richtext
              :value="data.content.richtext"
            />
            <Flex justify="center">
              <Link
                :data="data.content.cta.title"
                theme="button"
              />
            </Flex>
          </Spacer>
        </template>

        <template v-if="data.type === 'dialog'">
          <Spacer all="m">
            <Grid col="12">
              <Heading
                :value="data.content.title"
              />
              <Richtext
                :value="data.content.richtext"
              />
              <Flex justify="center">
                <Link
                  :data="{
                    fn: dialogFn,
                    title: data.content.cta.title,
                  }"
                  theme="button"
                />
              </Flex>
            </Grid>
          </Spacer>
        </template>
      </Wrapper>
    </Flex>
  </transition>
</template>

<script>
import Icon from '@/components/ui/icon';
import Heading from '@/components/typo/heading';
import Richtext from '@/components/typo/richtext';
import Link from '@/components/typo/link';

export default {
  name: 'Modal',
  components: {
    Icon,
    Heading,
    Richtext,
    Link,
  },
  data() {
    return {
      show: false,
      data: null,
      force: true,
    };
  },
  computed: {
    theme() {
      let theme = 'default';
      if (this.data && this.data.theme) theme = this.data.theme;

      return theme;
    },
  },
  watch: {
    show(val) {
      if (val) {
        document.body.classList.add('overflow-hidden');
        document.body.addEventListener('keydown', this.close);
      } else {
        document.body.classList.remove('overflow-hidden');
        document.body.removeEventListener('keydown', this.close);
      }
    },
  },
  mounted() {
    this.$bus.$on('modal', this.init);
    this.$bus.$on('openModal', this.open);
    this.$bus.$on('populateModal', this.content);
    this.$bus.$on('removeContentModal', this.remove);
  },
  beforeDestroy() {
    this.$bus.$off('modal', this.init);
    this.$bus.$off('openModal', this.open);
    this.$bus.$off('populateModal', this.content);
    this.$bus.$off('removeContentModal', this.remove);
  },
  methods: {
    init(data) {
      this.data = data;
      this.show = true;
    },
    content(data) {
      this.data = data;
      this.force = false;
    },
    open(id) {
      if (id === this.data.id) {
        this.show = true;
      }
    },
    close(e) {
      if (e.keyCode === 27 || e.type === 'click') {
        this.show = false;

        if (this.force) this.data = null;
      }
    },
    remove(id) {
      if (id) {
        if (this.data.id === id) {
          this.show = false;
          this.data = null;
        }
      }
    },
    dialogFn() {
      this.data.fn();
      this.remove(this.data.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;

  &-theme--default {
    .modal-wrapper {
      max-height: 80vh;
      background: var(--white);
      border-radius: var(--border-radius);
    }
  }
}
</style>
