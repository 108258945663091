<template>
  <main :class="{ single : true, [`single--${post.type}`]: post.type }">
    <SingleWork v-if="post.type === 'work'" />
    <Gutenberg
      v-else
      :data="post.gds_blocks"
    />
  </main>
</template>

<script>
import Gutenberg from '@/components/blocks';

import data from '@/mixins/data';
import SingleWork from '@/views/SingleWork.vue';

export default {
  name: 'Single',
  components: {
    Gutenberg,
    SingleWork,
  },
  mixins: [data],
};
</script>

<style lang="scss" scoped>
.single {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
}
</style>
