var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"gallery",staticClass:"gallery swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.data.value.gallery),function(item,index){return _c('div',{key:index,staticClass:"item swiper-slide"},[(item.type === 'image')?_c('Figure',{ref:"item",refInFor:true,attrs:{"data":{
          value: {
            src: item,
          },
          settings: {},
        },"lazyload":false,"disable-ratio":"","theme":"gallery"}}):(item.type === 'video')?_c('Video',{attrs:{"data":{
          value: {
            src: item,
          },
          settings: {
            source: 'file',
          },
        },"theme":"gallery"}}):_vm._e()],1)}),0),(!_vm.$mq.isMobile)?_c('div',{staticClass:"gallery--prev",on:{"click":_vm.slidePrev}},[_c('div',{ref:"prev"},[_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.gallery && _vm.gallery.activeIndex !== 0 ? 'Prev' : 'Prev work'
        )}})])]):_vm._e(),(!_vm.$mq.isMobile)?_c('div',{staticClass:"gallery--next",on:{"click":_vm.slideNext}},[_c('div',{ref:"next"},[_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.gallery && _vm.gallery.slides.length !== _vm.gallery.activeIndex + 1
            ? 'Next'
            : 'Next work'
        )}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }