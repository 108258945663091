<template>
  <div
    :class="{
      'single--work': true,
      'single--work--preview': $route.name === 'Preview',
    }"
    @mousemove="setMousePosition"
  >
    <Grid
      class="single--work--header t-uppercase"
    >
      <div class="single--work--title">
        <div v-html="post.title.rendered" />
        <div
          v-if="post.acf.subtitle"
          class="subtitle"
          v-html="post.acf.subtitle"
        />
      </div>
    </Grid>

    <Grid
      class="single--work--footer t-uppercase"
    >
      <div
        v-if="post.acf.subtitle"
        class="single--work--subtitle"
        v-html="post.acf.subtitle"
      />
    </Grid>

    <!-- <Wrapper
      boxed
      class=" t-uppercase"
    >
      <Flex justify="space-between">
        <div
          class="t-right"
        >
          {{ activeIndex + 1 }} / {{ totalIndex }}
        </div>
        <router-link :to="farFrom">
          Close
        </router-link>
      </Flex>
    </Wrapper> -->

    <div class="single--work--gallery">
      <Gallery
        ref="gallery"
        :data="post.acf.gallery"
        @next="nextProject"
        @prev="prevProject"
      />
    </div>
  </div>
</template>

<script>
import Gallery from '@/components/media/gallery';

import data from '@/mixins/data';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { mapGetters } from 'vuex';

export default {
  name: 'Single',
  components: {
    Gallery,
  },
  mixins: [data],
  data() {
    return {
      activeIndex: 0,
      totalIndex: 0,
      currentProjectIndex: 0,
      items: [],
    };
  },
  computed: {
    ...mapGetters(['request', 'farFrom']),

    swiperGallery() {
      return this.$refs.gallery?.gallery;
    },
  },
  mounted() {
    disableBodyScroll(this.$el);
    this.$nextTick(() => {
      this.totalIndex = this.swiperGallery?.slides.length;

      this.swiperGallery.on('slideChange', (swiper) => {
        const { query } = this.$route;

        this.activeIndex = swiper.activeIndex;
        if (!(query && query.id === this.activeIndex.toString())) {
          this.$router.push({ query: { id: this.activeIndex } });
        }
      });

      const { query } = this.$route;
      if (query && query.id) {
        this.swiperGallery.slideTo(parseInt(query.id, 10), 0);
      }

      this.swiperGallery.on('slideResetTransitionStart', (swiper) => {
        if (swiper.isEnd && swiper.swipeDirection === 'next') {
          this.nextProject();
        }
        if (swiper.isBeginning && swiper.swipeDirection === 'prev') {
          this.prevProject();
        }
      });
    });
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();
  },
  methods: {
    setMousePosition(event) {
      if (!this.$mq.isMobile) {
        document.documentElement.style.setProperty(
          '--mouseX',
          `${event.clientX}px`,
        );
        document.documentElement.style.setProperty(
          '--mouseY',
          `${event.clientY}px`,
        );
      }
    },
    async prevProject() {
      this.$router.push(this.$relativeUrl(this.post.adjacent.prev));
    },
    async nextProject() {
      this.$router.push(this.$relativeUrl(this.post.adjacent.next));
    },
  },
};
</script>

<style lang="scss" scoped>
.single {
  &--work {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--primary);
    &--header {
      mix-blend-mode: difference;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      padding: var(--spacer-s);
      width: 100%;
      @include mq(s) {
        top: calc(50% - 18px);
        bottom: auto;
      }
      @include mq(m) {
        top: calc(50% - 19px);
      }
    }
    &--title {
      color: white;
      grid-column: 1/13;
      @include mq(s) {
        grid-column: 5/13;
      }
      @include mq(m) {
        grid-column: 11/13;
        grid-column: 4/7;
      }
    }
    &--footer {
      mix-blend-mode: difference;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      padding: var(--spacer-s);
      width: 100%;
      display: none;
      @include mq(s) {
        display: grid;
      }
    }
    &--subtitle {
      color: white;
      display: none;
      font-size: 9px;
      letter-spacing: 0.01em;
      padding-top: 3px;
      @include mq(s) {
        display: block;
        grid-column: 5/13;
      }
      @include mq(m) {
        grid-column: 4/7;
      }
    }
    &--preview {
      top: 0;
      position: fixed;
      background: var(--primary);
      z-index: 10;
      width: 100%;
    }
    &--gallery {
      position: relative;
      height: 100%;
      text-transform: uppercase;
    }
  }
}
.subtitle {
  @include mq(s) {
    display: none;
  }
}
</style>
