<template>
  <div
    :class="['richtext']"
    v-html="value || data.innerHTML"
  />
</template>

<script>
export default {
  name: 'RichText',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: () => {},
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    if (window.twttr && this.mergeProps.value.includes('twitter-tweet')) {
      // eslint-disable-next-line no-undef
      window.twttr.widgets.load();
    }

    // // eslint-disable-next-line no-undef
    // FB.init({
    //   xfbml: true,
    //   version: 'v10.0',
    // });
  },
};
</script>

<style lang="scss">
  .t-indent {
    @include mq(s) {
      padding-left: var(--spacer-l);
      &--first-line {
        text-indent: var(--spacer-l);
      }
    }
  }
</style>
