var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",staticClass:"figure--container",attrs:{"to":_vm.link && !_vm.external && _vm.url ? _vm.url : undefined,"href":_vm.link && _vm.external ? _vm.url : undefined,"target":_vm.link && _vm.link.target ? _vm.link.target : undefined}},[_c('figure',{ref:"figure",class:[
      'figure',
      _vm.themeClass,
      _vm.disableRatio ? 'figure--native' : false,
    ],style:({
      '--image-w': _vm.image ? _vm.image.width : 0,
      '--image-h': _vm.image ? _vm.image.height : 0,
    }),attrs:{"data-lazy":_vm.lazyload ? 'false' : null}},[_c('div',{class:['image--wrapper']},[(_vm.image)?_c('img',{ref:"image",class:[
          'image',
          _vm.lazyload ? 'image--lazyload' : ''
        ],attrs:{"data-src":_vm.lazyload ? _vm.src : null,"src":!_vm.lazyload ? _vm.src : '',"alt":_vm.image.alt}}):_vm._e(),(_vm.lazyload)?_c('div',{staticClass:"placeholder"}):_vm._e()]),(_vm.caption)?_c('Richtext',{attrs:{"typo":"caption","tag":"figcaption","value":_vm.caption}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }