var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Archive',{ref:"archive",attrs:{"data":{
    value: {
      post_type: 'work',
    },
    settings: {
      posts_per_page: '50',
      filters: [],
      offset: 0,
      order: 'asc',
      infinite_scroll: false
    },
  },"thumb":"default"}})
}
var staticRenderFns = []

export { render, staticRenderFns }