<template>
  <div class="archive">
    <!-- <div class>
      <ThumbTitles
        v-for="(item) in items"
        :key="item.title + item.link"
        class="item"
        :data="item"
      />
    </div> -->
    <Thumb
      v-for="(item) in items"
      :key="item.link + item.index"
      class="item"
      :data="item"
    />

    <div
      v-show="items && data.settings.infinite_scroll"
      ref="infinite"
      class="infinite"
    />

    <Link
      v-if="data.value.link"
      :data="data.value.link"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import medusa from '@/assets/js/observer';

import Thumb from '@/components/thumb';
// import ThumbTitles from '@/components/thumb/titles';
import Link from '@/components/typo/link';

export default {
  name: 'Archive',
  components: {
    Link,
    Thumb,
    // ThumbTitles,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    thumb: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      request: null,
      items: [],
      offset: 0,
      placeholder: 'Loading',
    };
  },

  computed: {
    ...mapGetters(['postType']),
  },
  mounted() {
    this.setRequest();

    this.loadItems().then(() => {
      if (this.data.settings.infinite_scroll) {
        this.$nextTick(this.setInfiniteScroll);
      }
      this.$root.$children[0].lazyObserve(this.$el);
    });
  },
  beforeDestroy() {
    if (medusa.ref && medusa.ref.idList.includes('infinite-scroll')) {
      medusa.ref.removeTarget('infinite-scroll');
    }
  },
  methods: {
    async loadItems() {
      const items = await this.$store.dispatch('getItems', this.request);
      if (items) {
        this.items = [...items];

        // if (this.thumb === 'disorder') {
        //   this.items = [...items];
        // } else if (this.thumb === 'default') {
        //   this.galleryItems = [];
        //   items.forEach((item) => {
        //     item.acf.value.gallery.forEach((image) => {
        //       this.galleryItems.push({
        //         link: item.link,
        //         gds_featured_image: image,
        //         // index: i,
        //       });
        //     });
        //   });

        //   if (this.galleryItems.length) {
        //     this.shuffle(this.galleryItems);
        //     this.startImageShift();
        //   }
        // }
      }
      return items;
    },

    setRequest(per_page = false) {
      const type = this.postType(this.data.value.post_type).rest_base;
      per_page = !per_page
        ? (per_page = parseInt(this.data.settings.posts_per_page, 10))
        : per_page;

      const { order } = this.data.settings;
      const orderby = this.data.settings.order_by || 'menu_order';
      const { offset } = this;

      const filters = {};

      this.data.settings.filters.forEach((filter) => {
        const string = filter.split(':');
        // eslint-disable-next-line prefer-destructuring
        filters[string[0]] = string[1];
      });

      this.request = {
        type,
        params: {
          ...filters,
          per_page,
          offset,
          order,
          orderby,
          lang: this.$store.state.lang,
        },
      };
    },
    // setInfiniteScroll() {
    //   medusa.ref.addTarget({
    //     id: 'infinite-scroll',
    //     threshold: 0.0,
    //     nodes: [],
    //     mode: 'default',
    //     autoremove: false,
    //   });

    //   medusa.ref.pushToTarget(
    //     'infinite-scroll',
    //     this.$el.querySelector('.infinite'),
    //   );
    // },
  },
};
</script>

<style lang="scss" scoped>
.archive {
  position: relative;
}

.infinite {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  height: 50vh;
}
</style>
