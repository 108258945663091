<template>
  <nav>
    <div
      v-for="item in navigation"
      :key="item.id"
      :class="[...item.css]"
    >
      <Link
        :data="{
          url: item.url,
          title: item.content,
          target: item.target,
        }"
      />
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Link from '@/components/typo/link';

export default {
  name: 'Menu',
  components: {
    Link,
  },
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  pointer-events: auto;
  padding: var(--spacer-s) 0;
  display: block;
}
</style>
