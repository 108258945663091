var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"thumb",attrs:{"to":_vm.$relativeUrl(_vm.data.link)}},[_c('Grid',{staticClass:"thumb--header t-uppercase"},[_c('div',{staticClass:"thumb--title",domProps:{"innerHTML":_vm._s(_vm.data.title.rendered)}})]),(_vm.data.acf.value.gallery[0] && _vm.data.acf.value.gallery[0].type === 'image')?_c('Figure',{attrs:{"data":{
      value: {
        src: _vm.data.acf.value.gallery[0],
      },
      settings: {}
    }}}):(_vm.data.acf.value.gallery[0] && _vm.data.acf.value.gallery[0].type === 'video')?_c('Video',{attrs:{"data":{
      value: {
        src: _vm.data.acf.value.gallery[0],
      },
      settings: {
        source: 'file',
      },
    },"theme":"home"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }