<template>
  <router-link
    :to="$relativeUrl(data.link)"
    class="thumb"
  >
    <Grid class="thumb--header t-uppercase">
      <div
        class="thumb--title"
        v-html="data.title.rendered"
      />
    </Grid>
    <Figure
      v-if="data.acf.value.gallery[0] && data.acf.value.gallery[0].type === 'image'"
      :data="{
        value: {
          src: data.acf.value.gallery[0],
        },
        settings: {}
      }"
    />
    <Video
      v-else-if="data.acf.value.gallery[0] && data.acf.value.gallery[0].type === 'video'"
      :data="{
        value: {
          src: data.acf.value.gallery[0],
        },
        settings: {
          source: 'file',
        },
      }"
      theme="home"
    />
  </router-link>
</template>

<script>
import Figure from '@/components/media/figure';
import Video from '@/components/media/video';

export default {
  name: 'Thumb',
  components: {
    Figure,
    Video,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  display: block;
  position: relative;
}

.thumb--header {
  position: sticky;
  top: calc(50% - 18px);
  left: 0;
  // padding-top: 38px;
  padding: var(--spacer-s);
  // text-align: right;
  color: white;
  z-index: 11;
  mix-blend-mode: difference;

  @include mq(m) {
    top: calc(50% - 19px);
  }
}

.thumb--title {
  grid-column: 1/13;

  @include mq(s) {
    grid-column: 10/13;
    grid-column: 5/13;
  }

  @include mq(m) {
    grid-column: 11/13;
    grid-column: 4/13;
  }
}

.figure--container {
  margin-top: -36px;

  @include mq(m) {
    margin-top: -38px;
  }
}
</style>
