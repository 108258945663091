<template>
  <component
    :is="tag"
    :to="link && !external && url ? url : undefined"
    :href="link && external ? url : undefined"
    :target="link && link.target ? link.target : undefined"
    class="figure--container"
  >
    <figure
      ref="figure"
      :class="[
        'figure',
        themeClass,
        disableRatio ? 'figure--native' : false,
      ]"
      :data-lazy="lazyload ? 'false' : null"
      :style="{
        '--image-w': image ? image.width : 0,
        '--image-h': image ? image.height : 0,
      }"
    >
      <div :class="['image--wrapper']">
        <img
          v-if="image"
          ref="image"
          :class="[
            'image',
            lazyload ? 'image--lazyload' : ''
          ]"
          :data-src="lazyload ? src : null"
          :src="!lazyload ? src : ''"
          :alt="image.alt"
        >
        <div
          v-if="lazyload"
          class="placeholder"
        />
      </div>
      <Richtext
        v-if="caption"
        typo="caption"
        tag="figcaption"
        :value="caption"
      />
    </figure>
  </component>
</template>

<script>
import Richtext from '@/components/typo/richtext';

export default {
  name: 'Figure',
  components: {
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    lazyload: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
      default: () => {},
    },
    disableRatio: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      currentImg: 'default',
      src: null,
    };
  },
  computed: {
    settings() {
      return this.data.settings;
    },
    image() {
      return this.currentImg === 'mobile'
        && this.data.settings.toggle
          && this.data.value.src_mobile
        ? this.data.value.src_mobile
        : this.data.value.src;
    },
    caption() {
      if (this.data.settings.caption) {
        return this.data.value.caption
          ? this.data.value.caption
          : this.image.caption;
      }
      return false;
    },
    themeClass() {
      return this.theme
        ? `figure--${this.theme}`
        : this.settings.style
          ? `figure--${this.settings.style}`
          : false;
    },
    link() {
      return this.data.value.link;
    },
    url() {
      if (this.link) {
        if (this.link && this.link.url && typeof this.link.url === 'string') {
          return this.$relativeUrl(this.link.url);
        }
        return this.link.url;
      }
      return undefined;
    },
    external() {
      return (
        this.url && typeof this.url === 'string' && this.url.startsWith('http')
      );
    },
    tag() {
      return !this.link ? 'div' : this.external ? 'a' : 'router-link';
    },
  },
  mounted() {
    this.currentSrc();
    this.$bus.$on('windowResized', this.currentSrc);

    if (!this.lazyload) {
      this.load();
    }
  },
  beforeDestroy() {
    this.$bus.$off('windowResized', this.currentSrc);
  },
  methods: {
    currentSrc() {
      if (!this.image) {
        return;
      }
      let src = this.image.sizes.l;
      const { sizes } = this.image;
      const ratio = window.devicePixelRatio >= 2 ? 2 : 1;
      const wrapper = this.$parent.$el.offsetHeight;
      const dimension = 'height';
      const max = wrapper * ratio;
      const ranges = {
        s: sizes[`s-${dimension}`],
        m: sizes[`m-${dimension}`],
        l: sizes[`l-${dimension}`],
        xl: sizes[`xl-${dimension}`],
      };

      const sizesRange = Object.keys(ranges).filter(
        (key) => ranges[key] >= max,
      );
      const size = sizesRange.length > 0 ? sizesRange[0] : 'xl';

      this.$nextTick(() => {
        if (this.$mq.isMobile && this.data.settings.toggle) {
          this.currentImg = 'mobile';
        } else {
          this.currentImg = 'default';
        }
        src = this.image.sizes[size];

        if (this.$refs.figure.dataset.lazy === 'true') {
          this.$refs.image.src = src;
        }

        this.src = src;
        this.load();
      });
    },
    async load() {
      const img = this.$refs.image;

      if (this.src) {
        img.src = this.src;
      }

      if (img && img.decode && img.src) {
        await img.decode();
        this.loaded = true;
      } else {
        this.loaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.figure {
  margin: 0;
  position: relative;
  overflow: hidden;
  width: 100%;

  --width: var(--image-w);
  --height: var(--image-h);
  &--container {
    height: 100%;
  }

  .image--wrapper {
    position: relative;
    overflow: hidden;
    display: block;
  }

  &:not(.figure--native) {
    .image--wrapper {
      @include aspect-ratio(var(--width), var(--height));
    }
  }

  .image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: var(--fit, fill);
    object-position: var(--origin, 50% 50%);
  }

  &--native {
    .image {
      position: relative;
      position: initial;
      height: auto;
    }
  }

  // &--default {
    /* --width: 16;
      --height: 9;
      --fit: cover;
      --origin: bottom center; */
  // }

  &.align--top {
    .image {
      object-position: 50% 0;
    }
  }

  &.align--center {
    .image {
      object-position: 50% 50%;
    }
  }

  &.align--bottom {
    .image {
      object-position: 50% 100%;
    }
  }

  &.figure-contain {
    .image {
      object-fit: contain;
    }
  }

  .placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.2s $ease-custom;
    will-change: opacity;

    // background: black;
  }

  .image {
    visibility: hidden;
    transition: opacity .2s;
    will-change: opacity;

    &[src] {
      visibility: visible;
    }

    &--lazyload {
      opacity: 0;
    }
  }
  &[data-lazy="true"] {
    .placeholder {
      opacity: 0;
    }
    .image--lazyload {
      opacity: 1;
    }
  }

  &--gallery {
    height: 100%;
    .image--wrapper {
      height: 100%;
      .image {
        display: block;
        height: 100%;
        width: auto;
        max-width: 100%;
        margin: auto;
        object-fit: contain;
      }
    }
  }
}
</style>
