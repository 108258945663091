<template>
  <main class="page">
    <Grid>
      <Gutenberg
        :data="post.gds_blocks"
        class="richtext"
      />
    </Grid>
  </main>
</template>

<script>
import data from '@/mixins/data';

import Gutenberg from '@/components/blocks';

export default {
  name: 'Page',
  components: {
    Gutenberg,
  },
  mixins: [data],
};
</script>
<style lang="scss" scoped>
.page {
  padding: var(--spacer-s);
}

.richtext {
  grid-column: 1/13;
  padding-top: 36px;
  mix-blend-mode: difference;
  padding-top: calc((var(--vh) * 50) - 18px);

  @include mq(s) {
    grid-column: 5/11;
  }

  @include mq(m) {
    padding-top: calc((var(--vh) * 50) - 19px);
    grid-column: 4/11;
  }
}
</style>
