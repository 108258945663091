<template>
  <div
    ref="gallery"
    class="gallery swiper-container"
  >
    <div class="swiper-wrapper">
      <div
        v-for="(item, index) in data.value.gallery"
        :key="index"
        class="item swiper-slide"
      >
        <Figure
          v-if="item.type === 'image'"
          ref="item"
          :data="{
            value: {
              src: item,
            },
            settings: {},
          }"
          :lazyload="false"
          disable-ratio
          theme="gallery"
        />
        <Video
          v-else-if="item.type === 'video'"
          :data="{
            value: {
              src: item,
            },
            settings: {
              source: 'file',
            },
          }"
          theme="gallery"
        />
      </div>
    </div>
    <div
      v-if="!$mq.isMobile"
      class="gallery--prev"
      @click="slidePrev"
    >
      <div ref="prev">
        <span
          v-html="
            gallery && gallery.activeIndex !== 0 ? 'Prev' : 'Prev work'
          "
        />
      </div>
    </div>
    <div
      v-if="!$mq.isMobile"
      class="gallery--next"
      @click="slideNext"
    >
      <div ref="next">
        <span
          v-html="
            gallery && gallery.slides.length !== gallery.activeIndex + 1
              ? 'Next'
              : 'Next work'
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';

import Figure from '@/components/media/figure';
import Video from '@/components/media/video';

export default {
  name: 'Gallery',
  components: {
    Figure,
    Video,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      gallery: null,
      settings: {
        slidesPerView: 1,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.data.value.gallery.length > 0) {
        this.gallery = new Swiper(
          this.$refs.gallery,
          Object.assign(this.settings, this.extraSettings),
        );
      }
    });
    window.addEventListener('keydown', this.controlsKeyboard);
  },
  beforeUnmount() {
    this.gallery.destroy();
    window.removeEventListener('keydown', this.controlsKeyboard);
  },
  methods: {
    slideNext() {
      if (this.gallery.slides.length === this.gallery.activeIndex + 1) {
        if (this.$route.name === 'Preview') return;
        this.$emit('next');
      } else {
        this.gallery.slideNext();
      }
    },
    slidePrev() {
      if (this.gallery.activeIndex === 0) {
        if (this.$route.name === 'Preview') return;
        this.$emit('prev');
      } else {
        this.gallery.slidePrev();
      }
    },
    controlsKeyboard(e) {
      if (e.key === 'ArrowLeft') {
        this.slidePrev();
      }
      if (e.key === 'ArrowRight') {
        this.slideNext();
      }
    },
  },
};
</script>

<style lang="scss">
.gallery {
  overflow: hidden;
  height: 100%;
  width: 100%;

  .swiper-slide {
    width: auto;
    height: 100%;
  }

  &--prev,
  &--next {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: 9999;
    mix-blend-mode: difference;
    color: white;
    & > * {
      opacity: 0;
      @include mq(m) {
        left: var(--mouseX);
        top: var(--mouseY);
        white-space: nowrap;
        user-select: none;
        overflow: hidden;
      }
    }
    &:hover {
      @include mq(m) {
        cursor: none;
        & > * {
          position: fixed;
          opacity: 1;
        }
      }
    }
  }

  &--prev {
    left: 0;

  }

  &--next {
    right: 0;
  }
}
</style>
