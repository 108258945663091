<template>
  <div
    id="app"
    :style="`--scroll: ${prevScroll}px;`"
  >
    <AppHeader ref="header" />
    <transition
      :css="false"
      appear
      mode="out-in"
      @enter="enter"
      @leave="leave"
    >
      <router-view
        :key="farFrom.path"
        ref="parent"
        :class="['main-base', overlay ? 'main-base--overlay' : false]"
      />
    </transition>

    <transition
      :name="'slide-up'"
    >
      <router-view
        :key="$route.path"
        name="overlay"
      />
    </transition>
    <AppFooter />

    <Modal />
    <Snackbar />
    <Loader />
    <Cookie v-if="options && options.cookies" />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';

import AppHeader from '@/components/ui/header';
import AppFooter from '@/components/ui/footer';

import Modal from '@/components/ui/modal';
import Snackbar from '@/components/ui/snackbar';
import Loader from '@/components/ui/loader';
import Cookie from '@/components/ui/cookie';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    Modal,
    Cookie,
    Loader,
    Snackbar,
  },
  mixins: [lazyload],
  data() {
    return {
      prevScroll: 0,
      overlay: false,
      from: this.$route.name === 'work-Single' ? { path: '/' } : this.$route,
    };
  },
  computed: {
    ...mapGetters(['currentPost', 'options', 'farFrom']),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        const { pageYOffset } = window;
        if (to && to.name === 'work-Single') {
          document.body.classList.add('is-overlay');
          this.overlay = true;
          if (from && from.name !== 'work-Single') {
            this.prevScroll = pageYOffset;
          }
        } else if (
          from
          && from.name === 'work-Single'
          && to
          && to.name !== 'work-Single'
        ) {
          document.body.classList.remove('is-overlay');
          this.overlay = false;

          window.requestAnimationFrame(() => {
            window.scroll(0, this.prevScroll);
            this.prevScroll = 0;
          });
        }

        if (from && from.name !== 'work-Single') this.$store.commit('SET_FARFROM', from);

        this.from = to.name === 'work-Single'
          ? from && from.name && from.name !== 'work-Single'
            ? from
            : this.farFrom
              ? this.farFrom.fullPath
              : { path: '/' }
          : to;
      },
    },
  },
  created() {
    medusa.init();
  },
  mounted() {
    document.documentElement.style.setProperty('--primary', this.options.background);
    document.documentElement.style.setProperty('--secondary', this.options.text_color);
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
        Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
        this.$mq.vh();
      }, 400),
    );

    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' && this.overlay) {
        this.$router.push(this.farFrom);
      }
    });

    document.fonts.ready.then(() => {
      this.$store.commit('SET_FONTS', true);
    });
  },
  methods: {
    enter(el, done) {
      this.$store.commit('SET_TRANSITION', false);
      done();
    },
    leave(el, done) {
      this.$store.commit('SET_TRANSITION', true);
      done();
    },
    leaveOverlay() {},
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.app-loaded {
  #loader {
    display: none;
  }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-up-enter-active {
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}

.slide-up-leave-active {
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}
.slide-up-enter {
  transform: translateY(calc(var(--vh) * 100));
  // opacity: 0;
}

.slide-up-leave-to {
  transform: translateY(calc(var(--vh) * -100));
  // opacity: 0;
}

.slide-right-enter-active {
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}

.slide-right-leave-active {
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}
.slide-right-enter {
  transform: translateX(calc(var(--vh) * 100));
  // opacity: 0;
}

.slide-right-leave-to {
  transform: translateX(calc(var(--vh) * -100));
  // opacity: 0;
}

.main-base {
  // min-height: calc(100 * var(--vh, 1vh));
  position: relative;
  z-index: 0;
  &--overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    transform: translate3d(0, calc(-1 * var(--scroll, 0)), 0px);
    pointer-events: none;
    backface-visibility: hidden;
  }
}

html, body {
  // position: fixed;
  // top: 0;
  // left: 0;
  height: 100%;
  width: 100%;
}
</style>
